.infoIconsContainer {
    position: relative;
}

@media (max-width: 768px) {
    .infoIconsContainer {
        display: none;
    }
}

.relativeContainer {
    position: absolute;
    height: 30vw;
}

.infoIcon1 {
    top: 79vh;
    left: -32vw;
}

.infoIcon2 {
    top: 79vh;
    left: 27vw;
}

.infoIcon {
    position: absolute;
    width: 5vw;
    height: auto;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 100, 255, 0.5), 0 0 20px rgba(0, 100, 255, 0.5);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    z-index: 3;
}

.infoIcon:hover {
    transform: scale(1.2);
    box-shadow: 0 0 20px rgba(0, 100, 255, 0.7), 0 0 40px rgba(0, 100, 255, 0.7);
}

.relativeContainer1 {
    top: 70vh;
    left: -20vw;
    transform: translate(-50%, -50%);
    width: 30vw;
    z-index: 2;
}

.relativeContainer2 {
    top: 70vh;
    left: 24vw;
    transform: translate(-50%, -50%);
    width: 50vw;
    z-index: 2;
}

.hoverImgContainer {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}

/* Classes pour les images avec tailles spécifiques */
.imgInfoPanel1 {
    width: 24vw;
    height: auto;
    cursor: pointer;
}

.imgInfoPanel2 {
    width: 24vw;
    height: auto;
    cursor: pointer;
}

.imgRedArrow1 {
    width: 16vw;
    height: auto;
}

.imgRedArrow2 {
    width: 25vw;
    height: auto;
}

.imgRedArrow3 {
    width: 14vw;
    height: auto;
}

.imgRedArrow4 {
    width: 10vw;
    height: auto;
}

.imgRedArrow6 {
    width: 9vw;
    height: auto;
}

.imgDiscoveryLogo {
    width: 4vw;
    height: auto;
}

.imgMusicLogo {
    width: 4vw;
    height: auto;
}

.expandLogo {
    position: absolute;
    width: 10vw;
    height: auto;
    top: -22%;
    left: -39%;
    z-index: 3;
}

.expandLogo .fullscreen-icon {
    width: 7.2vw;
    height: 3.6vw;
    z-index: 3;
}

.expandLogo .fullscreen-button {
    width: 3.6vw;
    height: auto;
}

.expandLogo .fullscreen-icon.enter {
    transform: translateX(0);
}

.expandLogo .fullscreen-icon.exit {
    transform: translateX(-3.6vw);
}

.langLogo {
    position: absolute;
    top: -37%;
    left: -39%;
}

.langLogo .flag {
    width: 7.2vw;
    height: 3.6vw;
    z-index: 3;
}

.langLogo .language-switcher {
    width: 3.6vw;
    height: auto;
}

.langLogo .flag.en {
    transform: translateX(0);
}

.langLogo .flag.fr {
    transform: translateX(-3.6vw);
}

.imgCharacterLoading {
    width: 20vw;
    height: auto;
}

.imgInfoPanelHighlight1 {
    width: 12vw;
    height: auto;
}

.imgInfoPanelHighlight2 {
    width: 10.5vw;
    height: auto;
}

/* Positionnement des conteneurs */
.hoverImgContainer.infoPanel1 {
    top: -7%;
    left: 11%;
}

.hoverImgContainer.infoPanel2 {
    top: -6%;
    left: 16%;
}

.hoverImgContainer.infoPanelHighlight1 {
    top: 23.3%;
    left: 32.3%;
}

.hoverImgContainer.redArrow2 {
    top: 12%;
    left: -9%;
}

.hoverImgContainer.redArrow1 {
    top: -38%;
    left: 25%;
}

.hoverImgContainer.discoveryLogo {
    top: -7%;
    left: -39%;
}

.hoverImgContainer.musicLogo {
    top: -52%;
    left: -39%;
}

.hoverImgContainer.characterLoading {
    top: 0%;
    left: 60%;
}

.hoverImgContainer.redArrow6 {
    top: -8%;
    left: 79%;
}

.hoverImgContainer.redArrow3 {
    top: 27%;
    left: 48%;
}

.hoverImgContainer.infoPanelHighlight2 {
    top: 24.7%;
    left: 29.8%;
}

.hoverImgContainer.redArrow4 {
    top: -25%;
    left: 61%;
}

/* Styles pour les hoverText */
.hoverText {
    position: absolute;
    background-color: #081a36;
    color: #0064ff;
    padding: 5px 10px;
    border: 2px solid #0064ff;
    border-radius: 16px;
    white-space: nowrap;
    z-index: 1000;
    font-size: calc(16px + 0.5vw);
    font-family: "LXGW WenKai Mono TC", monospace;
    box-shadow: 0 0 10px #0064ff;
    text-align: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.hoverText:hover {
    background-color: #040e1c;
    transform: scale(1.05);
    box-shadow: 0 0 20px #0064ff, 0 0 30px #0064ff;
}

/* Styles pour les buttonPanel */
.buttonPanel {
    position: absolute;
    background-color: #040a58;
    color: #0064ff;
    padding: 1vh 2vw;
    border: 2px solid #0064ff;
    border-radius: 40px;
    z-index: 1000;
    font-size: calc(16px + 0.5vw);
    max-width: 11vw;
    font-family: "LXGW WenKai Mono TC", monospace;
    box-shadow: 0 0 10px #0064ff;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.buttonPanel1 {
    top: 63%;
    left: 35%;
}

.buttonPanel2 {
    top: 65%;
    left: 24%;
}

.buttonPanel.visible {
    pointer-events: auto;
    opacity: 1;
}

.buttonPanel:hover {
    background-color: #010c1f;
    transform: scale(1.05);
    box-shadow: 0 0 20px #0064ff, 0 0 30px #0064ff;
}

.buttonPanel:active {
    background-color: #1a1a1a;
    box-shadow: 0 0 20px #003c7f, 0 0 30px #003c7f;
}

/* Styles pour les hoverText spécifiques */
.hoverText1a {
    top: 23%;
    left: -35%;
}

.hoverText1b {
    top: -5%;
    left: -20%;
}

.hoverText1c {
    top: -20%;
    left: -20%;
}

.hoverText1d {
    top: -35%;
    left: -20%;
}

.hoverText1e {
    top: -50%;
    left: -20%;
}

.hoverText2a {
    top: -16%;
    left: 35%;
    text-wrap: wrap;
    width: 12vw;
}

.hoverText2b {
    top: -28%;
    left: 35%;
}

.hoverText2c {
    top: -27%;
    left: 83%;
    text-wrap: wrap;
    width: 7vw;
}