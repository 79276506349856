.contactFormContainer {
    position: absolute;
    top: 641vw;
    left: 50%;
    transform: translateX(-50%);
    width: 50vw;
    aspect-ratio: 4 / 3;
    border-radius: 1em;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.contactForm {
    display: flex;
    flex-direction: column;
    gap: 1vw;
    width: 90%;
    color: #fff;
}

.contactForm h2 {
    font-size: 2vw;
    text-align: center;
    color: #ff9800;
    background: rgba(255, 255, 255, 0.2);
    border: 3px solid black;
    padding: 1.2vw;
    border-radius: 0.7em;
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5), inset 0 0 10px rgba(255, 255, 255, 0.3);
    transition: all 0.3s ease;
    backdrop-filter: blur(2px);
}

.contactForm h2:hover {
    color: #ff5722;
    backdrop-filter: blur(4px);
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.6), inset 0 0 15px rgba(255, 255, 255, 0.4);
}


.formGroup {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.formGroup input,
.formGroup textarea {
    padding: 1vw;
    border-radius: 0.5em;
    border: 2px solid black;
    font-size: 1.4vw;
    font-weight: 600;
    background: rgba(255, 255, 255, 0.2);
    color: #111;
    outline: none;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease;
    backdrop-filter: blur(2px);
    width: 100%;
    box-sizing: border-box;
}

.formGroup textarea {

    height: 10vw;
}

.formGroup input::placeholder,
.formGroup textarea::placeholder {
    color: #111;
    font-weight: normal;
}

.formGroup input:focus,
.formGroup textarea:focus {
    background: rgba(255, 255, 255, 0.4);
    border-color: #a9a9a9;
    box-shadow: 0 0 15px rgba(169, 169, 169, 0.7), inset 0 0 10px rgba(0, 0, 0, 0.5);
    transform: scale(1.02);
    backdrop-filter: none;
}

.formGroup textarea {
    resize: none;
    flex-grow: 1;
}

.submitButton {
    padding: 1vw 2vw;
    border: 3px solid black;
    border-radius: 0.5em;
    background: rgba(70, 70, 70, 0.8);
    color: #ffffff;
    font-size: 1.5vw;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.6);
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    align-self: center;
    width: 50%;
}

.submitButton:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    box-shadow:
        -7px -7px 20px 0px #fff9,
        -4px -4px 5px 0px #fff9,
        7px 7px 20px 0px #0002,
        4px 4px 5px 0px #0001;
    transition: all 0.3s ease;
}

.submitButton:hover {
    color: #000;
    background: rgba(105, 105, 105, 0.6);
    border-color: #a9a9a9;
    transform: translateY(-0.3em);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.7);
}

.submitButton:hover:after {
    left: auto;
    right: 0;
    width: 100%;
}

.submitButton:active {
    top: 0.2em;
}

.formRow {
    display: flex;
    gap: 1vw;
    width: 100%;
}

.formGroupHalf {
    flex: 1;
}

.formGroupFull {
    width: 100%;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    animation: fadeIn 0.5s ease-in-out, fadeOut 0.5s ease-in-out 2.5s forwards;
}

.overlayContent {
    color: #ffffff;
    background-color: rgba(19, 97, 51, 0.8);
    padding: 2vw;
    border-radius: 1em;
    text-align: center;
    font-size: 2vw;
    animation: popIn 0.3s ease, popOut 0.5s ease 2.5s forwards;
    border: 3px solid #fff;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5), 0 0 15px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    transition: all 0.3s ease;
}

.overlay.error .overlayContent {
    background-color: rgba(126, 30, 19, 0.8);
}


@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes popIn {
    from {
        transform: scale(0.8);
    }

    to {
        transform: scale(1);
    }
}

@keyframes popOut {
    from {
        transform: scale(1);
        opacity: 1;
    }

    to {
        transform: scale(0.8);
        opacity: 0;
    }
}