.App {
  text-align: center;
  background-color: red;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.hover-container {
  position: relative;
  display: inline-block;
}

.hover-text {
  position: absolute;
  background-color: #d4c5b4;
  color: black;
  padding: 5px 10px;
  border: 1px solid black;
  border-radius: 5px;
  display: none;
  white-space: nowrap;
  top: 115%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  font-size: 1.5vh;
  font-family: Arial, sans-serif;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  text-align: center;

}

.hover-container:hover .hover-text {
  display: block;
}