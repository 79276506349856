.minimap-container {
    position: relative;
}

.minimap {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 14;
    opacity: 0;
    transition: opacity 0.7s ease;
}

.minimap:hover {
    opacity: 1;
}

.loadingScreen~.minimap {
    display: none;
}

.minimap img {
    width: auto;
    height: 100vh;
    -webkit-mask-image: linear-gradient(to left, rgba(0, 0, 0, 1) 15%, rgba(0, 0, 0, 0) 100%);
    mask-image: linear-gradient(to left, rgba(0, 0, 0, 1) 15%, rgba(0, 0, 0, 0) 100%);
}

.minimap-hover-text {
    position: fixed;
    background-color: #d4c5b4;
    color: black;
    padding: 5px 10px;
    border: 1px solid black;
    border-radius: 5px;
    white-space: nowrap;
    z-index: 1000;
    font-size: 1.5vh;
    font-family: Arial, sans-serif;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    text-align: center;
    display: none;
}

.minimap-hover-point-text {
    position: absolute;
    background-color: #d4c5b4;
    color: black;
    padding: 5px 10px;
    border: 1px solid black;
    border-radius: 5px;
    white-space: nowrap;
    z-index: 1000;
    font-size: 1.5vh;
    font-family: Arial, sans-serif;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    text-align: center;
    display: none;
    font-weight: bold;
}

.minimap-point {
    position: absolute;
    left: 70%;
    transform: translateX(-50%);
    width: 17px;
    height: 17px;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease, border 0.3s ease;
    border: 2px solid rgba(255, 255, 255, 0.6);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.minimap .navigation,
.minimap .presentation,
.minimap .agility,
.minimap .philosophy,
.minimap .technology,
.minimap .persoproject,
.minimap .miscellaneous {
    width: 12px;
    height: 12px;
    left: 70%;
    transform: translateX(-50%);
}

.minimap-point:hover {
    transform: scale(1.5) translateX(-33%);
    box-shadow: 0 0 15px var(--point-color), 0 0 30px var(--point-color), 0 0 45px var(--point-color);
    border: 2px solid var(--point-color);
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

.minimap-point:hover::after {
    content: "";
    position: absolute;
    top: -5px;
    left: -5px;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.3);
    animation: pulse 1s infinite;
    box-shadow: 0 0 15px var(--point-color), 0 0 30px var(--point-color), 0 0 45px var(--point-color);
}