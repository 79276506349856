.completeSection {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: clip;
}

.starrySkyBg {
    position: absolute;
    width: 100%;
    z-index: -1;
    overflow-x: hidden;
}

.floatingWrapper {
    display: inline-block;
    position: relative;
}