.downloadButtonResumeContainer {
    position: absolute;
    top: 617vw;
    left: 44vw;
    z-index: 11;
}

.downloadButtonResumeLink {
    display: inline-block;
    position: relative;
    overflow: visible;
}

.downloadButtonResume {
    width: 8vw;
    height: auto;
}

.downloadButtonResumeLink::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 250%;
    height: 250%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0) 70%);
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(1) rotate(0deg);
    filter: blur(15px);
    opacity: 0;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    pointer-events: none;
}

.downloadButtonResumeLink:hover::before {
    transform: translate(-50%, -50%) scale(1.2) rotate(10deg);
    opacity: 1;
    filter: blur(20px);
    animation: shimmer 1.5s infinite alternate ease-in-out, pulseGlow 2s infinite alternate ease-in-out;
}

.customTooltip {
    position: absolute;
    background-color: #d4c5b4;
    color: black;
    padding: 5px 10px;
    border: 1px solid black;
    border-radius: 5px;
    white-space: nowrap;
    top: 115%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.5vh;
    font-family: Arial, sans-serif;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    transform: translateX(-50%) translateY(10px);
}

.downloadButtonResumeWrapper:hover .customTooltip {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
}

.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: fadeIn 0.3s forwards;
}

.confirmPopup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.9);
    background: rgba(255, 255, 255, 0.5);
    border: 3px solid black;
    backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 12px;
    width: max-content;
    max-width: 40vw;
    min-width: 30vw;
    height: auto;
    box-sizing: border-box;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
    font-family: "LXGW WenKai Mono TC", monospace;
    font-size: 3vw;
    font-weight: 800;
    color: rgba(12, 12, 12);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    opacity: 0;
    animation: popupAppear 0.3s forwards, popupDisappear 0.3s reverse forwards paused;
}

.popupButtons {
    display: flex;
    gap: 20px;
}

.popupButtons {
    display: flex;
    gap: 20px;
}

.confirmButton {
    padding: 10px 20px;
    border: 2px solid #4caf50;
    background: rgba(76, 175, 80, 0.7);
    color: white;
    cursor: pointer;
    border-radius: 8px;
    font-size: 1.8vw;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.cancelButton {
    padding: 10px 20px;
    border: 2px solid #f44336;
    background: rgba(244, 67, 54, 0.7);
    color: white;
    cursor: pointer;
    border-radius: 8px;
    font-size: 1.8vw;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.confirmButton:hover {
    background: rgba(76, 175, 80, 0.9);
    transform: scale(1.1);
}

.cancelButton:hover {
    background: rgba(244, 67, 54, 0.9);
    transform: scale(1.1);
}

.boldOrange {
    display: inline-block;
    font-weight: bold;
    color: #ff9800;
}

.textConfirmPopup {
    margin-top: 2rem;
}

@keyframes popupAppear {
    0% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.9);
    }

    100% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}

@keyframes shimmer {
    0% {
        opacity: 0.8;
        filter: blur(15px);
    }

    100% {
        opacity: 1;
        filter: blur(20px);
    }
}

@keyframes pulseGlow {
    0% {
        box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
    }

    100% {
        box-shadow: 0 0 40px rgba(255, 255, 255, 0.8);
    }
}