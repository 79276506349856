.fullscreen-container {
    position: relative;
    display: inline-block;
}

.fullscreen-button {
    width: 32px;
    height: 32px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    transition: transform 0.3s ease-in-out;
}

.fullscreen-button:hover {
    transform: scale(1.5);
}

.fullscreen-icon {
    width: 64px;
    height: 32px;
    transition: transform 0.3s ease;
}

.fullscreen-icon.enter {
    transform: translateX(0);
}

.fullscreen-icon.exit {
    transform: translateX(-32px);
}