.bigPlanetContainer {
    position: absolute;
    top: 44vw;
    left: 62vw;
    z-index: 4;
}

.atmosphBigPlanetContainer {
    position: absolute;
    top: 40vw;
    left: 59vw;
    z-index: 4;
}

.planet1Container {
    position: absolute;
    top: 18vw;
    left: 77vw;
    z-index: 1;
}

.planet2Container {
    position: absolute;
    top: 10vw;
    left: 3vw;
    z-index: 1;
}

.planet3Container {
    position: absolute;
    top: 43vw;
    left: 56vw;
    z-index: 1;
}

.planet4Container {
    position: absolute;
    top: 36vw;
    left: 6vw;
    z-index: 1;
}

.planet5Container {
    position: absolute;
    top: 142vw;
    left: 12vw;
    z-index: 1;
}

.planet6Container {
    position: absolute;
    transform: rotate(13deg);
    top: 80vw;
    left: 36vw;
    z-index: 1;
}

.planet7Container {
    position: absolute;
    top: 100vw;
    left: 30vw;
    z-index: 1;
}

.planet8Container {
    position: absolute;
    top: 75vw;
    left: 70vw;
    z-index: 2;
}

.planet9Container {
    position: absolute;
    top: 160vw;
    left: 60vw;
    z-index: 1;
}

.planet10Container {
    position: absolute;
    top: 75vw;
    left: 61vw;
    z-index: 1;
}

.planet11Container {
    position: absolute;
    top: 100vw;
    left: 7vw;
    z-index: 3;
}


.bigPlanet {
    width: 50vw;
    height: auto;
}

.atmosphBigPlanet {
    width: 56vw;
    height: auto;
}

.planet1 {
    width: 9vw;
    height: auto;
}

.planet2 {
    width: 6vw;
    height: auto;
}

.planet3 {
    width: 8vw;
    height: auto;
}

.planet4 {
    width: 13vw;
    height: auto;
}

.planet5 {
    width: 12vw;
    height: auto;
}

.planet6 {
    width: 10vw;
    height: auto;
}

.planet7 {
    width: 12vw;
    height: auto;
}

.planet8 {
    width: 17vw;
    height: auto;
}

.planet9 {
    width: 12vw;
    height: auto;
}

.planet10 {
    width: 7vw;
    height: auto;
}

.planet11 {
    width: 30vw;
    height: auto;
}