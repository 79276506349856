/* CSS for circleClouds  */
.circleCloud1Container {
    position: absolute;
    top: 245vw;
    left: 79vw;
    z-index: 3;
}

.circleCloud2Container {
    position: absolute;
    top: 235vw;
    left: 12vw;
    z-index: 3;
}

.circleCloud3Container {
    position: absolute;
    top: 230vw;
    left: 15vw;
    z-index: 2;
}

.circleCloud4Container {
    position: absolute;
    top: 235vw;
    left: -9vw;
    z-index: 3;
}

.circleCloud5Container {
    position: absolute;
    top: 256vw;
    left: 27vw;
    z-index: 3;
}

.circleCloud6Container {
    position: absolute;
    top: 255vw;
    left: 5vw;
    z-index: 2;
}

.circleCloud7Container {
    position: absolute;
    top: 265vw;
    left: 42vw;
    z-index: 5;
}

.circleCloud8Container {
    position: absolute;
    top: 250vw;
    left: 12vw;
    z-index: 3;
}

.circleCloud9Container {
    position: absolute;
    top: 258vw;
    left: 74vw;
    z-index: 4;
}

.circleCloud10Container {
    position: absolute;
    top: 246vw;
    left: 41vw;
    z-index: 3;
}

.circleCloud11Container {
    position: absolute;
    top: 257vw;
    left: 79vw;
    z-index: 3;
}

.circleCloud12Container {
    position: absolute;
    top: 245vw;
    left: 50vw;
    z-index: 3;
}

.circleCloud13Container {
    position: absolute;
    top: 254vw;
    left: 59vw;
    z-index: 2;
}

.circleCloud14Container {
    position: absolute;
    top: 220vw;
    left: 0vw;
    z-index: 1;
}

.circleCloud15Container {
    position: absolute;
    top: 230vw;
    left: 54vw;
    z-index: 4;
}

.circleCloud16Container {
    position: absolute;
    top: 227vw;
    left: 59vw;
    z-index: 3;
}

.circleCloud1 {
    width: 32vw;
    height: 30vw;
}

.circleCloud2 {
    width: 22vw;
    height: auto;
}

.circleCloud3 {
    width: 20vw;
    height: 16vw;
}

.circleCloud4 {
    width: 60vw;
    height: 52vw;
}

.circleCloud5 {
    width: 24vw;
    height: auto;
}

.circleCloud6 {
    width: 15vw;
    height: auto;
}

.circleCloud7 {
    width: 34vw;
    height: auto;
}

.circleCloud8 {
    width: 12vw;
    height: auto;
}

.circleCloud9 {
    width: 20vw;
    height: 18vw;
}

.circleCloud10 {
    width: 37vw;
    height: 35vw;
}

.circleCloud11 {
    width: 10vw;
    height: auto;
}

.circleCloud12 {
    width: 20vw;
    height: 19vw;
}

.circleCloud13 {
    width: 12vw;
    height: 11vw;
}

.circleCloud14 {
    width: 95vw;
    height: auto;
}

.circleCloud15 {
    width: 18vw;
    height: 16vw;
}

.circleCloud16 {
    width: 9vw;
    height: auto;
}

/* CSS for flyingCity */
.city1Container {
    position: absolute;
    top: 631vw;
    left: 1vw;
    z-index: 2;
}

.city2Container {
    position: absolute;
    top: 580vw;
    left: 7vw;
    z-index: 6;
}

.city3Container {
    position: absolute;
    top: 643vw;
    left: 72vw;
    z-index: 2;
}

.city4Container {
    position: absolute;
    top: 627vw;
    left: 13vw;
    z-index: 5;
}

.city5Container {
    position: absolute;
    top: 612vw;
    left: 54vw;
    z-index: 6;
}

.flyingRock1Container {
    position: absolute;
    top: 536vw;
    left: 4vw;
    z-index: 4;
}

.flyingRock2Container {
    position: absolute;
    top: 520vw;
    left: 50vw;
    z-index: 4;
}

.flyingRock3Container {
    position: absolute;
    top: 558vw;
    left: 83vw;
    z-index: 3;
}

.flyingRock4Container {
    position: absolute;
    top: 568vw;
    left: 68vw;
    z-index: 3;
}

.flyingRock5Container {
    position: absolute;
    top: 529vw;
    left: 62vw;
    z-index: 4;
}

.flyingRock6Container {
    position: absolute;
    top: 559vw;
    left: 30vw;
    z-index: 4;
}

.flyingRock7Container {
    position: absolute;
    top: 522vw;
    left: 0vw;
    z-index: 2;
}

.flyingRock8Container {
    position: absolute;
    top: 527vw;
    left: 0vw;
    z-index: 2;
}

.flyingRock9Container {
    position: absolute;
    top: 540vw;
    left: 0vw;
    z-index: 3;
}

.flyingRock10Container {
    position: absolute;
    top: 559vw;
    left: 0vw;
    z-index: 2;
}

.flyingRock11Container {
    position: absolute;
    top: 578vw;
    left: 0vw;
    z-index: 3;
}

.flyingRock12Container {
    position: absolute;
    top: 582vw;
    left: 0vw;
    z-index: 4;
}


.skyShip1Container {
    position: absolute;
    top: 393vw;
    left: 51vw;
    z-index: 3;
}

.skyShip2Container {
    position: absolute;
    top: 269vw;
    left: 56vw;
    z-index: 6;
}

.skyShip3Container {
    position: absolute;
    top: 267vw;
    left: 32vw;
    z-index: 4;
}

.skyShip4Container {
    position: absolute;
    top: 407vw;
    left: 3vw;
    z-index: 4;
}

.skyShip5Container {
    position: absolute;
    top: 470vw;
    left: 30vw;
    z-index: 4;
}

.skyShip6Container {
    position: absolute;
    top: 463vw;
    left: 64vw;
    z-index: 2;
}

.skyShip7Container {
    position: absolute;
    top: 470vw;
    left: 15vw;
    z-index: 3;
}

.skyShip8Container {
    position: absolute;
    top: 480vw;
    left: 4vw;
    z-index: 4;
}

.skyShip9Container {
    position: absolute;
    top: 427vw;
    left: 12vw;
    z-index: 4;
}

.skyShip10Container {
    position: absolute;
    top: 431vw;
    left: 61vw;
    z-index: 3;
}

.skyShip11Container {
    position: absolute;
    top: 421vw;
    left: 69vw;
    z-index: 3;
}

.skyShip12Container {
    position: absolute;
    top: 412vw;
    left: 35vw;
    z-index: 3;
}

.skyShip13Container {
    position: absolute;
    top: 310vw;
    left: 12vw;
    z-index: 5;
}

.skyShip14Container {
    position: absolute;
    top: 342vw;
    left: 67vw;
    z-index: 4;
}

.skyShip15Container {
    position: absolute;
    top: 335vw;
    left: 22vw;
    z-index: 4;
}

.skyShip16Container {
    position: absolute;
    top: 311vw;
    left: 34vw;
    z-index: 4;
}

.skyShip17Container {
    position: absolute;
    top: 331vw;
    left: 13vw;
    z-index: 3;
}

.skyShip18Container {
    position: absolute;
    top: 321vw;
    left: 68vw;
    z-index: 4;
}

.skyShip19Container {
    position: absolute;
    top: 372vw;
    left: 13vw;
    z-index: 3;
}

.montgolfiere1Container {
    position: absolute;
    top: 515vw;
    left: 69vw;
    z-index: 4;
}

.montgolfiere2Container {
    position: absolute;
    top: 520vw;
    left: 4vw;
    z-index: 8;
}


.city1 {
    width: 7vw;
    height: auto;
}

.city2 {
    width: 52vw;
    height: auto;
}

.city3 {
    width: 8vw;
    height: auto;
}

.city4 {
    width: 20vw;
    height: auto;
}

.city5 {
    width: 34vw;
    height: auto;
}


/* CSS for flyingRocks */
.flyingRock1 {
    width: 29vw;
    height: auto;
}

.flyingRock2 {
    width: 19vw;
    height: auto;
}

.flyingRock3 {
    width: 11vw;
    height: auto;
}

.flyingRock4 {
    width: 30vw;
    height: auto;
}

.flyingRock5 {
    width: 33vw;
    height: auto;
}

.flyingRock6 {
    width: 15vw;
    height: auto;
}

.flyingRock7 {
    width: 100vw;
    height: auto;
}

.flyingRock8 {
    width: 100vw;
    height: auto;
}

.flyingRock9 {
    width: 100vw;
    height: auto;
}

.flyingRock10 {
    width: 100vw;
    height: auto;
}

.flyingRock11 {
    width: 100vw;
    height: auto;
}

.flyingRock12 {
    width: 100vw;
    height: auto;
}

/* CSS for skyShips */
.skyShip1 {
    width: 36vw;
    height: auto;
}

.skyShip2 {
    width: 45vw;
    height: auto;
}

.skyShip3 {
    width: 31vw;
    height: auto;
}

.skyShip4 {
    width: 42vw;
    height: auto;
}

.skyShip5 {
    width: 44vw;
    height: auto;
}

.skyShip6 {
    width: 30vw;
    height: auto;
}

.skyShip7 {
    width: 16vw;
    height: auto;
}

.skyShip8 {
    width: 21vw;
    height: auto;
}

.skyShip9 {
    width: 22vw;
    height: auto;
}

.skyShip10 {
    width: 12vw;
    height: auto;
}

.skyShip11 {
    width: 15vw;
    height: auto;
}

.skyShip12 {
    width: 18vw;
    height: auto;
}

.skyShip13 {
    width: 20vw;
    height: auto;
}

.skyShip14 {
    width: 20vw;
    height: auto;
}

.skyShip15 {
    width: 17vw;
    height: auto;
}

.skyShip16 {
    width: 6vw;
    height: auto;
}

.skyShip17 {
    width: 15vw;
    height: auto;
}

.skyShip18 {
    width: 22vw;
    height: auto;
}

.skyShip19 {
    width: 25vw;
    height: auto;
}

.montgolfiere1 {
    width: 16vw;
    height: auto;
}

.montgolfiere2 {
    width: 42vw;
    height: auto;
}


/* CSS for longClouds */
.longCloud1Container {
    position: absolute;
    top: 606vw;
    left: 0vw;
    z-index: 5;

}

.longCloud2Container {
    position: absolute;
    top: 588vw;
    left: 0vw;
    z-index: 6;
}

.longCloud3Container {
    position: absolute;
    top: 616vw;
    left: 0vw;
    z-index: 4;
}

.longCloud4Container {
    position: absolute;
    top: 627vw;
    left: 0vw;
    z-index: 3;
}

.longCloud5Container {
    position: absolute;
    top: 633vw;
    left: 0vw;
    z-index: 2;
}

.longCloud6Container {
    position: absolute;
    top: 265vw;
    left: 0vw;
    z-index: 3;
}

.longCloud7Container {
    position: absolute;
    top: 335vw;
    left: 0vw;
    z-index: 3;
}

.longCloud8Container {
    position: absolute;
    top: 400vw;
    left: 0vw;
    z-index: 3;
}

.longCloud9Container {
    position: absolute;
    top: 471vw;
    left: 0vw;
    z-index: 3;
}

.longCloud10Container {
    position: absolute;
    top: 527vw;
    left: 0vw;
    z-index: 3;
}

.longCloud11Container {
    position: absolute;
    top: 276vw;
    left: 0vw;
    z-index: 4;
}

.longCloud12Container {
    position: absolute;
    top: 359vw;
    left: 0vw;
    z-index: 4;
}

.longCloud13Container {
    position: absolute;
    top: 516vw;
    left: 0vw;
    z-index: 3;
}

.longCloud14Container {
    position: absolute;
    top: 446vw;
    left: 0vw;
    z-index: 4;
}

.longCloud15Container {
    position: absolute;
    top: 333vw;
    left: 0vw;
    z-index: 4;
}

.longCloud16Container {
    position: absolute;
    top: 324vw;
    left: 0vw;
    z-index: 6;
}

.longCloud17Container {
    position: absolute;
    top: 413vw;
    left: 0vw;
    z-index: 6;
}

.longCloud18Container {
    position: absolute;
    top: 499vw;
    left: 0vw;
    z-index: 6;
}

.longCloud19Container {
    position: absolute;
    top: 584vw;
    left: 0vw;
    z-index: 2;
}

.longCloud20Container {
    position: absolute;
    top: 260vw;
    left: 0vw;
    z-index: 2;
}

.longCloud21Container {
    position: absolute;
    top: 346vw;
    left: 0vw;
    z-index: 2;
}

.longCloud22Container {
    position: absolute;
    top: 505vw;
    left: 0vw;
    z-index: 2;
}

.longCloud23Container {
    position: absolute;
    top: 423vw;
    left: 0vw;
    z-index: 2;
}


.longCloud1,
.longCloud2,
.longCloud3,
.longCloud4,
.longCloud5,
.longCloud6,
.longCloud7,
.longCloud8,
.longCloud9,
.longCloud10,
.longCloud11,
.longCloud12,
.longCloud13,
.longCloud14,
.longCloud15,
.longCloud16,
.longCloud17,
.longCloud18,
.longCloud19,
.longCloud20,
.longCloud21,
.longCloud22,
.longCloud23 {
    width: 100.2vw;
    height: auto;
}

.longCloud1Container.clone,
.longCloud2Container.clone,
.longCloud3Container.clone,
.longCloud4Container.clone,
.longCloud5Container.clone,
.longCloud6Container.clone,
.longCloud7Container.clone,
.longCloud8Container.clone,
.longCloud9Container.clone,
.longCloud10Container.clone,
.longCloud11Container.clone,
.longCloud12Container.clone,
.longCloud13Container.clone,
.longCloud14Container.clone,
.longCloud15Container.clone,
.longCloud16Container.clone,
.longCloud17Container.clone,
.longCloud18Container.clone,
.longCloud19Container.clone,
.longCloud20Container.clone,
.longCloud21Container.clone,
.longCloud22Container.clone,
.longCloud23Container.clone {
    left: -200vw;
}


/* CSS for ground */
.groundPart1Container {
    position: absolute;
    top: 672.8vw;
    left: 77.2vw;
    z-index: 6;
}

.groundPart2Container {
    position: absolute;
    top: 656.2vw;
    left: -4vw;
    z-index: 8;
}

.groundPart3Container {
    position: absolute;
    top: 665.9vw;
    left: 35.5vw;
    z-index: 7;
}

.groundPart4Container {
    position: absolute;
    top: 649.9vw;
    left: 42vw;
    z-index: 4;
}

.groundPart5Container {
    position: absolute;
    top: 654.9vw;
    left: 66.4vw;
    z-index: 3;
}

.groundPart6Container {
    position: absolute;
    top: 654.2vw;
    left: -4vw;
    z-index: 6;
}

.groundPart7Container {
    position: absolute;
    top: 636.6vw;
    left: -1.5vw;
    z-index: 5;
}

.groundPart8Container {
    position: absolute;
    top: 646.6vw;
    left: 4.6vw;
    z-index: 4;
}

.groundPart9Container {
    position: absolute;
    top: 652vw;
    left: 13.9vw;
    z-index: 3;
}

.groundPart10Container {
    position: absolute;
    top: 657.5vw;
    left: 65.1vw;
    z-index: 3;
}

.groundPart11Container {
    position: absolute;
    top: 677vw;
    left: -1vw;
    z-index: 9;

}

.groundPart12Container {
    position: absolute;
    top: 661.1vw;
    left: 50.1vw;
    z-index: 2;
}

.groundPart1 {
    width: 24vw;
    height: auto;
}

.groundPart2 {
    width: 49vw;
    height: auto;
}

.groundPart3 {
    width: 41vw;
    height: auto;
}

.groundPart4 {
    width: 62vw;
    height: auto;
}

.groundPart5 {
    width: 37vw;
    height: auto;
}

.groundPart6 {
    width: 29vw;
    height: auto;
}

.groundPart7 {
    width: 52vw;
    height: auto;
}

.groundPart8 {
    width: 54vw;
    height: auto;
}

.groundPart9 {
    width: 60vw;
    height: auto;
}

.groundPart10 {
    width: 34vw;
    height: auto;
}

.groundPart11 {
    width: 101vw;
    height: auto;
}

.groundPart12 {
    width: 10vw;
    height: auto;
}