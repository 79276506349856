.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.modalContent {
    position: relative;
    width: 60vw;
    height: 70vh;
    max-width: 80vw;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(0, 95, 255, 0.4);
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(10px);
    text-align: center;
    padding: 30px;
    overflow-y: auto;
    z-index: 1001;
    opacity: 0;
    transform-origin: top;

    /* interdire le curseur */
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}


.modalTitle {
    font-size: 2.5vw;
    font-weight: bold;
    color: white;
    margin-bottom: 1.5vw;
}

.modalBody {
    font-size: 1.5vw;
    color: #e0e0e0;
    max-width: 90%;
    text-align: justify;
    line-height: 1.5;
    margin-bottom: 2vw;
}

.closeButton {
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 10px;
    font-size: 1.5rem;
    color: white;
    background: transparent;
    border: none;
    cursor: pointer;
    transition: transform 0.2s ease, color 0.2s ease;
}

.closeButton:hover {
    transform: scale(1.1);
    color: rgba(0, 191, 255, 0.7);
}

.closeButton:active {
    transform: scale(0.9);
}


.modalBody strong {
    font-weight: bold;
    color: #ffffff;
}

/* Emphase en orange avec effet de soulignement dynamique */
.modalBody .emphasis {
    font-weight: bold;
    color: #ff6600;
    position: relative;
    display: inline-block;
}

.modalBody .emphasis::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #ff6600;
    bottom: -3px;
    left: 0;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
}

.modalBody .emphasis:hover::after {
    transform: scaleX(1);
}

/* Sous-titres structurés mais discrets */
.modalBody .subTitle {
    font-size: 1.1em;
    font-weight: bold;
    color: #cc5500;
    /* Orange plus doux que les mots en emphase */
    letter-spacing: 0.05em;
    /* Espacement léger pour aérer */
    border-bottom: 2px solid rgba(204, 85, 0, 0.3);
    /* Ligne subtile sous le titre */
    padding-bottom: 3px;
    /* Espacement avec la ligne */
    margin-top: 1.8rem;
    margin-bottom: 0.8rem;
    /* Réduit l’espace après pour coller au  */
}


/* Espacement ajusté pour une meilleure lisibilité */
.modalBody p {
    margin: 1.5rem 0;
    line-height: 1.8;
}