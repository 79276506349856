.lazy-image-container {
    position: relative;
}

.lazy-image-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
}

.lazy-image-placeholder.loading {
    opacity: 0.5;
}

.lazy-image {
    width: 100%;
    height: auto;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.lazy-image.loaded {
    opacity: 1;
}

.lazy-image.loading {
    opacity: 0;
}