.hamburger-menu {
    position: relative;
}

.hamburger-icon {
    font-size: 2rem;
    cursor: pointer;
    color: white;
}

.menu-items {
    position: absolute;
    top: 50px;
    right: 0;
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
}

.menu-items a,
.menu-items span {
    color: white;
    text-decoration: none;
    font-family: 'Nova Mono', monospace;
    font-size: 1.2em;
}

.menu-items a:hover,
.menu-items span:hover {
    color: #ccc;
}