.language-switcher {
    width: 32px;
    height: 30px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    transition: transform 0.3s ease-in-out;

}

.language-switcher:hover {
    transform: scale(1.5);
}

.flag {
    width: 64px;
    height: 30px;
    transition: transform 0.3s ease;
}

.flag.en {
    transform: translateX(0);
}

.flag.fr {
    transform: translateX(-32px);
}