.mosaicContainer {
    position: absolute;
    width: auto;
    height: auto;
    top: 28vh;
    left: -1vw;
    opacity: 1;
    z-index: 1;

}

.olivine {
    position: absolute;
    opacity: 1;
    object-fit: contain;
    transition: transform 0.3s ease-in-out;
    overflow: hidden;

}