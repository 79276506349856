@import url("https://fonts.googleapis.com/css2?family=Italiana&display=swap");

.geology-page {
    height: 100vh;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    font-family: "Italiana", sans-serif;
}

.title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.geology-title {
    color: beige;
    font-size: 7rem;
    font-weight: 700;
    line-height: 1.3;
    margin: 20px 0 10px 0;
    letter-spacing: 0.05em;
    z-index: 2;
}

.geology-subtitle {
    color: beige;
    font-size: 4rem;
    font-weight: 400;
    line-height: 1.3;
    margin: 15vh 0;
    letter-spacing: 0.05em;
    z-index: 2;
}

.geology-subtitle::after {
    content: '';
    display: block;
    width: 50%;
    height: 2px;
    background: beige;
    margin: 3vh auto 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}