.close-control-button {
    position: absolute;
    padding: 0px 28px;
    font-size: 4vh;
    cursor: pointer;
    background: transparent;
    border: none;
    border-radius: 20px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    z-index: 1000;
}

.close-control-button:hover {
    transform: scale(1.1);
    box-shadow: var(--hover-shadow, 0 0 10px rgba(0, 191, 255, 0.7), 0 0 20px rgba(0, 191, 255, 0.5));
}

.close-control-button:active {
    transform: scale(0.9);
}

.close-control-button svg {
    transition: filter 0.2s ease;
}

.close-control-button:hover svg {
    filter: drop-shadow(var(--hover-shadow, 0 0 5px rgba(0, 191, 255, 0.7)) drop-shadow(0 0 10px rgba(0, 191, 255, 0.5)));
}

.section-view {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.section-content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70vw;
    height: 80vh;
    overflow: visible;
}

.background-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
    z-index: -1;
}

.background-image {
    width: 100%;
    height: 100%;
    object-fit: fill;
    position: relative;
    z-index: 2;
}

.background-filter {
    position: absolute;
    top: -5px;
    left: -5px;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    background: #005fff70;
    backdrop-filter: blur(6px);
    z-index: 1;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 10px #0096ff, 0 0 15px #0096ff;
    transition: backdrop-filter 0.3s ease;
}

.content-overlay {
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    box-sizing: border-box;
}

.button-section {
    position: absolute;
    z-index: 4;
    top: 0.3vh;
    left: 63vw;
}

.text-container {
    position: absolute;
    top: 6%;
    left: 6%;
    width: calc(93% - 20px);
    height: 86%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
}

.section-inner-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
    margin: 1vw 3vw;
}

.section-inner-content::-webkit-scrollbar {
    width: 0px;
    display: none;
}

.scrollbar-section {
    width: 5vh;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 5;
    position: relative;
}

.custom-scrollbar-container {
    position: relative;
    width: 100%;
    height: 20%;
    top: 0;
}

.custom-scrollbar {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 0;
    cursor: pointer;
    background-position: center;
}

.scrollbar-trail {
    width: 50%;
    height: 150%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    clip-path: ellipse(50% 30% at 50% 50%);
    filter: brightness(1.2) blur(2px);
}

.section-title {
    font-family: "LXGW WenKai Mono TC", monospace;
    font-size: 3vw;
    font-weight: bold;
    color: black;
    text-align: center;
    text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
}

.section-subtitle {
    font-family: "LXGW WenKai Mono TC", monospace;
    font-size: 1.8vw;
    font-weight: bold;
    color: rgb(7, 7, 7);
    text-align: center;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    margin-top: 3vw;
    padding-top: 1vw;
    border-top: 6px solid darkred;
}

.section-paragraph {
    font-family: "LXGW WenKai Mono TC", monospace;
    font-size: 1.8vw;
    font-weight: normal;
    color: rgb(12, 12, 12);
    padding: 10px;
    text-align: center;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    background: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    margin-bottom: 10px;
}

.section-subsubtitle {
    margin-top: 2vw;
}

.section-list {
    font-family: "LXGW WenKai Mono TC", monospace;
    font-size: 1.8vw;
    font-weight: normal;
    color: rgb(12, 12, 12);
    list-style-type: disc;
    padding-left: 40px;
    margin-bottom: 20px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    text-align: left;
}

.section-list li {
    margin-bottom: 10px;
}

.section-image {
    max-width: 50%;
    height: auto;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}


.section-highlight .highlight-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.section-highlight .highlight-image {
    width: 8vw;
    height: auto;
    margin-right: 20px;
}

.section-highlight .highlight-text-container {
    display: flex;
    flex-direction: column;
}

.section-highlight .highlight-text {
    font-family: "LXGW WenKai Mono TC", monospace;
    font-size: 1.8vw;
    font-weight: bold;
    margin-bottom: 5px;
    color: black;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.section-highlight .highlight-text:hover {
    transform: scale(1);
}

.section-highlight .highlight-content {
    font-family: "LXGW WenKai Mono TC", monospace;
    font-size: 1.5vw;
    color: rgb(12, 12, 12);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    text-align: justify;
    font-weight: 600;
}

.close-section-button-container .hover-text {
    position: absolute;
    background-color: #d4c5b4;
    color: black;
    padding: 5px 10px;
    border: 1px solid black;
    border-radius: 5px;
    display: none;
    white-space: nowrap;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    font-size: 1.5vh;
    font-family: Arial, sans-serif;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    text-align: center;
}

.hover-container:hover .hover-text {
    display: block;
}

.close-section-button {
    font-size: 20px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.close-section-button:hover {
    transform: scale(1.1);
    box-shadow: var(--hover-shadow, 0 0 10px rgba(0, 191, 255, 0.7), 0 0 20px rgba(0, 191, 255, 0.5));
}

.close-section-button:active {
    transform: scale(0.9);
}

.introduction .section-title {
    color: #FFFFFF;
}

.introduction .section-subtitle {
    color: #DDDDDD;
}

.introduction .section-paragraph {
    color: lightgray;
}

.introduction .close-control-button {
    color: #DDDDDD;
}

.introduction .section-list {
    color: lightgray;
}