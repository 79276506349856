.asteroides1,
.asteroides1-clone,
.asteroides2,
.asteroides2-clone,
.asteroides3,
.asteroides3-clone {
    position: absolute;
    width: 100vw;
    height: auto;
    left: 0vw;

}

.asteroides1,
.asteroides1-clone {
    top: 29vw;
    z-index: 2;
}

.asteroides2,
.asteroides2-clone {
    top: 25vw;
    z-index: 3;
}

.asteroides3,
.asteroides3-clone {
    top: 22vw;
    z-index: 4;
}

.asteroides1-clone,
.asteroides2-clone,
.asteroides3-clone {
    left: 100vw;
}