#navbar {
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    z-index: 15;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    margin: 2% 0;

    /* interdire le curseur */
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

#navbarInner {
    z-index: 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    height: 100%;
    padding-bottom: 1%;
}

#navItems {
    display: flex;
    gap: 1.5vw;
    align-items: center;
    color: white;
    font-family: 'Nova Mono', monospace;
    font-size: 1em;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s, opacity 0.3s ease;
}

#navItems.visible {
    visibility: visible;
    opacity: 1;
}

#navItems>* {
    flex-grow: 1;
    text-align: center;
}

#navItems span {
    cursor: pointer;
}

#navButtons {
    display: flex;
    gap: 1vw;
    align-items: center;
}

#hamburgerMenu {
    display: none;
}

@media screen and (max-width: 905px) {
    #navItems {
        display: none;
    }

    #hamburgerMenu {
        display: block;
    }

    #navButtons {
        display: flex;
        gap: 1vw;
        align-items: center;
    }
}

#navItems a,
#navItems span,
.menu-items a,
.menu-items span {
    text-decoration: none;
    font-family: 'Nova Mono', monospace;
    font-size: 1.2em;
    position: relative;
    padding: 5px 10px;
    transition: color 0.3s ease, border-color 0.3s ease;
    border: 2px solid transparent;
}

#navItems a::before,
#navItems span::before,
.menu-items a::before,
.menu-items span::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: white;
    transition: transform 0.3s ease;
    transform: scaleX(0);
    transform-origin: right;
}

#navItems a:hover::before,
#navItems span:hover::before,
.menu-items a:hover::before,
.menu-items span:hover::before {
    transform: scaleX(1);
    transform-origin: left;
}

.nav-item-dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.9);
    min-width: 160px;
    padding: 10px;
    border-radius: 8px;
    opacity: 0;
    transform: translateY(-10px) translateX(-50%);
    transition: opacity 0.3s ease, transform 0.3s ease;
    pointer-events: none;
    left: 50%;
    z-index: 1;
}

.nav-item-dropdown:hover .dropdown-content {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
    pointer-events: auto;
}

.dropdown-content span {
    color: white;
    padding: 8px 12px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.dropdown-content span:hover {
    transform: translateX(5px);
}

/* navbar colors hover */
#navItems .nav-home:hover {
    color: #0060FF;
}

#navItems .nav-skills:hover {
    color: rgba(29, 192, 228);
}

#navItems .nav-role:hover {
    color: #36BF0096;
}

#navItems .nav-project:hover {
    color: #ff3917;
}

#navItems .nav-journey:hover {
    color: #FFD700;
}

#navItems .nav-contact:hover {
    color: #bfb6a8;
}

#navItems .nav-dropAgil:hover {
    color: #1dc0e4;
}

#navItems .nav-dropTech:hover {
    color: #FF9C00;
}

#navItems .nav-persoProj:hover {
    color: #ff3917;
}

#navItems .nav-proProj:hover {
    color: #ff3917;
}

.vertical-separator {
    height: 24px;
    width: 2px;
    background-color: #ccc;
    margin: 0 15px;
    display: inline-block;
    vertical-align: middle;
}

.nav-baseCamp {
    position: relative;
    padding: 10px 20px;
    color: #FF5733;
    text-align: center;
}

.baseCamp-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    opacity: 0;
    border-radius: 15px;
    transition: opacity 0.2s ease;
    mask-image: radial-gradient(circle, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0) 100%);
    filter: blur(1px);
}

.nav-baseCamp:hover .baseCamp-bg {
    opacity: 0.6;
    border-radius: 15px;
}

#navbar.basecamp #navItems a,
#navbar.basecamp #navItems span {
    color: #FF5733;
}

#navbar.basecamp #navItems a:hover,
#navbar.basecamp #navItems span:hover {
    color: #FF8C00;

}