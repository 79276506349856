.character-animated {
    position: absolute;
    height: 15vw;
    width: 15vw;
}

.character-animated img {
    position: relative;
}

.character-image {
    position: absolute;
}

.body {
    top: 26%;
    left: 12%;

}

.head {
    top: 9%;
    left: 7%;
}

.left-arm-character1 {
    top: 26%;
    left: 27%;

}

.right-arm-character1 {
    top: 27%;
    right: 83%;
    z-index: -1;
}

.left-arm-character2 {
    top: 27%;
    left: 29%;
    z-index: -1;

}

.right-arm-character2 {
    top: 27%;
    right: 80%;
}

.bodyImg {
    width: 3vw;
}

.headImg {
    width: 4.5vw;
}

.left-arm-character1Img {
    width: 1.8vw;
}

.left-arm-character2Img {
    width: 1.8vw;
}

.right-arm-character1Img {
    width: 1.8vw;
}

.right-arm-character2Img {
    width: 2vw;
}

.character3 .body,
.character11 .body,
.character13 .body,
.character16 .body,
.character19 .body,
.character5 .body {
    top: 33%;
}

.character3 .left-arm-character2,
.character11 .left-arm-character2,
.character13 .left-arm-character2,
.character16 .left-arm-character2,
.character19 .left-arm-character2,
.character5 .left-arm-character2 {
    top: 36%;
    left: 37%;
}

.character3 .right-arm-character2,
.character11 .right-arm-character2,
.character13 .right-arm-character2,
.character16 .right-arm-character2,
.character19 .right-arm-character2,
.character5 .right-arm-character2 {
    top: 32%;
    right: 77%;
}


.character3 .headImg,
.character11 .headImg,
.character13 .headImg,
.character16 .headImg,
.character19 .headImg,
.character5 .headImg {
    width: 6.4vw;
}

.character3 .bodyImg,
.character11 .bodyImg,
.character13 .bodyImg,
.character16 .bodyImg,
.character19 .bodyImg,
.character5 .bodyImg {
    width: 4.5vw;
}

.character3 .left-arm-character2Img,
.character11 .left-arm-character2Img,
.character13 .left-arm-character2Img,
.character16 .left-arm-character2Img,
.character19 .left-arm-character2Img,
.character5 .left-arm-character2Img {
    width: 2.4vw;
}


.character3 .right-arm-character2Img,
.character11 .right-arm-character2Img,
.character13 .right-arm-character2Img,
.character16 .right-arm-character2Img,
.character19 .right-arm-character2Img,
.character5 .right-arm-character2Img {
    width: 3vw;
}


.character-hover-text {
    position: absolute;
    top: -6vh;
    left: 5vw;
    transform: translateX(-50%);
    background-color: #d3d3d370;
    color: black;
    padding: 5px 10px;
    font-size: 0.9vw;
    display: none;
    white-space: normal;
    word-wrap: break-word;
    max-width: 8vw;
    width: 7vw;
    z-index: 1;
    opacity: 0;
    border-radius: 20px;
    border: 1px solid black;
    text-align: center;
    transition: opacity 0.3s ease-in-out;
}



.character-animated:hover .character-hover-text {
    display: block;
    opacity: 1;
}

.left .character-hover-text {
    top: -18%;
    left: 45%;
}

.right .character-hover-text {
    top: -18%;
    left: 0%;
}