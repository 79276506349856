.character {
    position: absolute;
    z-index: 10;
    cursor: pointer;
}

.speech-bubble {
    background: lightgrey;
    border: 3px solid black;
    backdrop-filter: blur(2px);
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    width: max-content;
    max-width: 30vw;
    min-width: 25vw;
    height: auto;
    white-space: pre-wrap;
    box-sizing: border-box;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    word-wrap: break-word;
    text-align: center;
    font-family: "LXGW WenKai Mono TC", monospace;
    transition: color 0.3s ease, transform 0.3s ease;

    /* Désactiver la sélection de texte sur les navigateurs compatibles */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    display: flex;
    flex-direction: column;
}

.speech-bubble-right {
    position: absolute;
    top: -3vh;
    left: 7vw;
}

.speech-bubble-left {
    position: absolute;
    top: -3vh;
    right: 0vw;
}

.speech-bubble:hover {
    transform: scale(1.1);
}


.bubble-title {
    font-size: 2.3vw;
    font-weight: bold;
    color: black;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.bubble-subtitle {
    font-size: 2vw;
    font-weight: bold;
    color: rgb(7, 7, 7);
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
}

.bubble-paragraph {
    font-size: 1.8vw;
    font-weight: 800;
    color: rgb(12, 12, 12);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.bubble-subsubtitle {
    font-size: 2.1vw;
    font-weight: bold;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.6),
        0 0 10px rgba(113, 117, 121, 0.5);
    letter-spacing: 0.1vw;
    transform: scale(1.05);
    border-bottom: 2px solid rgb(32, 32, 32);
    border-top: 2px solid rgb(32, 32, 32);
}


.bubble-image {
    max-width: 100%;
    height: auto;
}

.green-icon,
.blue-icon {
    vertical-align: middle;
}

.green-icon {
    color: #08ca08;
}

.blue-icon {
    color: #0052cc;
}

.view-section-button,
.close-section-button,
.control-button {
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    background: transparent;
    border: none;
    border-radius: 20px;
    display: inline-flex;
    align-items: center;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.view-section-button .fas,
.close-section-button .fas {
    margin-right: 5px;
}

.view-section-button:hover,
.close-section-button:hover,
.control-button:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.view-section-button:active,
.close-section-button:active,
.control-button:active {
    transform: scale(0.9);
}

.close-bubble-button {
    background: transparent;
    color: black;
    border: none;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.view-section-button {
    color: black;
}

.minimized-bubble {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #DDDDDD;
    border: 3px solid black;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    position: absolute;
}

.speech-bubble-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
}

.speech-bubble-header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.indicator-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.control-button {
    padding: 5px 10px;
    font-size: 2vw;
    cursor: pointer;
    background: transparent;
    border: none;
    border-radius: 20px;
    display: inline-flex;
    align-items: center;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.control-button .fas {
    margin-right: 5px;
}

.control-button:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.control-button:active {
    transform: scale(0.9);
}

.view-hover-container .view-hover-text,
.close-hover-container .close-hover-text {
    top: -33px;
    left: 50%;
    transform: translateX(-50%);
}


.social-link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    padding: 5px;
    background-color: lightgrey;
    border: 2px solid black;
    border-radius: 8px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.social-link-anchor {
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
}

.social-link-logo {
    width: 5vw;
    height: 5vw;
    margin-right: 8px;
    transition: transform 0.2s;
}

.social-link-logo:hover {
    transform: scale(1.1);
}

.social-link-text {
    font-size: 1.8vw;
    font-weight: 800;
    color: rgb(12, 12, 12);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.social-link:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.character1 .bubble-title,
.character2 .bubble-title,
.character3 .bubble-title,
.character4 .bubble-title,
.character5 .bubble-title {
    color: #FFFFFF;
}

.character1 .bubble-subtitle,
.character2 .bubble-subtitle,
.character3 .bubble-subtitle,
.character4 .bubble-subtitle,
.character5 .bubble-subtitle {
    color: #DDDDDD;
}

.character1 .bubble-paragraph,
.character2 .bubble-paragraph,
.character3 .bubble-paragraph,
.character4 .bubble-paragraph,
.character5 .bubble-paragraph {
    color: lightgray;
}

.character1 .close-bubble-button,
.character2 .close-bubble-button,
.character3 .close-bubble-button,
.character4 .close-bubble-button,
.character5 .close-bubble-button {
    color: #DDDDDD;
}

.character1 .view-section-button,
.character2 .view-section-button,
.character3 .view-section-button,
.character4 .view-section-button,
.character5 .view-section-button {
    color: #DDDDDD;
}