.highlight-message {
    position: absolute;
    z-index: 1000;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.5s ease;
    display: flex;
    align-items: center;
}

.highlight-message.left {
    flex-direction: row;
}

.highlight-message.right {
    flex-direction: row;
}

.highlight-image {
    width: 8vw;
    height: auto;
    display: block;
    margin: 0 10px;
    transition: color 0.3s ease, transform 0.3s ease;
}

.highlight-text {
    font-size: 1.8vw;
    color: rgb(12, 12, 12);
    text-align: center;
    font-family: "Arial", sans-serif;
    font-weight: bold;
    padding: 5px 10px;
    background-color: rgba(253, 156, 21, 0.7);
    border: 3px solid black;
    border-radius: 25px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: color 0.3s ease, transform 0.3s ease;
    display: inline-block;
    margin-top: 10px;
    backdrop-filter: blur(2px);
}

.highlight-text:hover {
    color: #553103;
    transform: scale(1.1);
    text-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    background-color: rgba(235, 207, 149, 0.9);
}

.highlight-image:hover {
    transform: scale(1.1);
}

.bubble-list-item {
    font-family: "LXGW WenKai Mono TC", monospace;
    list-style-type: disc;
    padding-left: 20px;
    text-align: left;

    font-size: 1.8vw;
    color: rgb(12, 12, 12);
    font-weight: 800;
}

.end-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
}

.end-icon {
    font-size: 6vw;
}

.end-text {
    position: absolute;
    font-size: 1.8vw;
    color: rgb(20, 20, 20);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    font-weight: 800;
    padding: 6px;
}

.highlight-underline {
    position: relative;
    color: #ff9800;
    display: inline-block;
    transition: transform 0.3s ease, color 0.3s ease;
}

.highlight-underline::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: #ff9800;
    animation: underlineAnimation 4s infinite;
}

.highlight-underline:hover {
    transform: scale(1.1);
    color: #ff5722;
}

.highlight-underline2 {
    position: relative;
    color: #7b0707;
    display: inline-block;
    transition: transform 0.3s ease, color 0.3s ease;
}

.highlight-underline2::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: #973000;
    animation: underlineAnimation 4s infinite;
}

.highlight-underline2:hover {
    transform: scale(1.1);
    color: #551400;
}

.highlight-bold2 {
    display: inline-block;
    font-weight: bold;
    color: #7b0707;
    transition: transform 0.3s ease, color 0.3s ease;
}

.highlight-bold2:hover {
    transform: scale(1.1);
    color: #551400;
}

.highlight-italic2 {
    display: inline-block;
    font-style: italic;
    color: #7b0707;
    transition: transform 0.3s ease, color 0.3s ease;
}

.highlight-italic2:hover {
    transform: scale(1.05);
    color: #551400;
}

@keyframes underlineAnimation {
    0% {
        transform: scaleX(0);
        transform-origin: left;
    }

    25% {
        transform: scaleX(1);
        transform-origin: left;
    }

    50% {
        transform: scaleX(1);
        transform-origin: right;
    }

    75% {
        transform: scaleX(0);
        transform-origin: right;
    }

    100% {
        transform: scaleX(0);
        transform-origin: left;
    }
}

.highlight-bold {
    display: inline-block;
    font-weight: bold;
    color: #ff9800;
    transition: transform 0.3s ease, color 0.3s ease;
}

.highlight-bold:hover {
    transform: scale(1.1);
    color: #ff5722;
}

.highlight-italic {
    display: inline-block;
    font-style: italic;
    color: #ff9800;
    transition: transform 0.3s ease, color 0.3s ease;
}

.highlight-italic:hover {
    transform: scale(1.05);
    color: #ff5722;
}

.highlight-turquoise {
    position: relative;
    color: #00a2ff;
    display: inline-block;
    font-weight: bold;
}

.highlight-orange {
    position: relative;
    color: #ff6600;
    display: inline-block;
    font-weight: bold;
}

.highlight-slide {
    position: relative;
    color: #ff9800;
    display: inline-block;
}

.highlight-subsubtile {
    font-size: 2.1vw;
    font-weight: bold;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.6),
        0 0 10px rgba(113, 117, 121, 0.5);
    letter-spacing: 0.1vw;
    transform: scale(1.05);
    border-bottom: 2px solid rgb(32, 32, 32);
    border-top: 2px solid rgb(32, 32, 32);
}

.highlight-slide::before,
.highlight-slide::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: #ff9800;
    left: 0;
    bottom: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform 0.5s ease;
}

.highlight-slide::before {
    top: 0;
    bottom: auto;
    transform-origin: left;
}

.highlight-slide:hover::before {
    transform: scaleX(1);
    transform-origin: right;
}

.highlight-slide:hover::after {
    transform: scaleX(1);
    transform-origin: left;
}

.highlight-slide::after {
    transform-origin: left;
    bottom: 0;
}

.highlight-slide:hover {
    color: #ff5722;
    transition: color 0.5s ease;
}

.character1 .end-text,
.character2 .end-text,
.character3 .end-text,
.character4 .end-text,
.character5 .end-text {
    color: #BBBBBB;
}

.character1 .bubble-list-item,
.character2 .bubble-list-item,
.character3 .bubble-list-item,
.character4 .bubble-list-item,
.character5 .bubble-list-item {
    color: lightgray;
}

.slide-counter {
    font-size: 1.2vw;
    font-weight: bold;
    color: #FFFFFF;
    margin: 0 15px;
}