.logo-container {
    position: relative;
    width: 5vw;
    height: 5vw;
    cursor: pointer;
}

.logo {
    position: absolute;
    width: 100%;
    z-index: 5;
}

.ray {
    opacity: 1;
}

.ray,
.halo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 175%;
    height: 175%;
    pointer-events: none;
    z-index: -1;
}

.ray {
    height: auto;
    transform-origin: center;
}

.ray1 {
    width: 25%;
    top: 50%;
    left: 22%;
}

.ray2 {
    width: 29%;
    top: 43%;
    left: 22%;
}

.ray3 {
    width: 30%;
    top: 38%;
    left: 23%;
}

.ray4 {
    width: 22%;
    top: 34%;
    left: 24%;
}

.ray5 {
    width: 25%;
    top: 36%;
    left: 31%;
}

.ray6 {
    width: 14%;
    top: 23%;
    left: 32%;
}

.ray7 {
    width: 10%;
    top: 18%;
    left: 37%;
}

.ray8 {
    width: 6%;
    top: 22%;
    left: 46%;
}

.ray9 {
    width: 7%;
    top: 12%;
    left: 54%;
}

.ray10 {
    width: 10%;
    top: 29%;
    left: 60%;
}

.ray11 {
    width: 13%;
    top: 25%;
    left: 69%;
}

.ray12 {
    width: 18%;
    top: 30%;
    left: 75%;
}

.ray13 {
    width: 25%;
    top: 37%;
    left: 78%;
}

.ray14 {
    width: 19%;
    top: 43%;
    left: 83%;
}

.ray15 {
    width: 23%;
    top: 52%;
    left: 81%;
}

.halo {
    background: radial-gradient(circle, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 70%);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.logo-container:hover .halo {
    opacity: 1;
}