.music-player {
  width: 32px;
  height: 32px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  transition: transform 0.3s ease-in-out;

}

.music-player:hover {
  transform: scale(1.5);
}


.music-icon {
  width: 64px;
  height: 32px;
  transition: transform 0.3s ease;
}

.music-icon.play {
  transform: translateX(0);
}

.music-icon.pause {
  transform: translateX(-32px);
}