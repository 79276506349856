.mainPanelContainer {
    position: absolute;
    z-index: 10;
    font-family: "LXGW WenKai Mono TC", monospace;
    transition: transform 0.1s ease-out;
    will-change: transform;
}

.mainPanel {
    background: #6cefffb3;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    padding: 0.8vw;
    cursor: pointer;
    font-size: 1.3vw;
    text-align: center;
    color: rgb(7, 7, 7);
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
    font-weight: bold;
    position: relative;
    max-width: 8vw;
    min-width: fit-content;
    transition: transform 0.4s ease, box-shadow 0.4s ease, background 0.4s ease;
    will-change: transform, box-shadow;
}

.mainPanel:hover {
    transform: translateY(-5px) scale(1.05);
    box-shadow: 0 10px 30px rgba(0, 95, 255, 0.5), 0 0 20px rgba(108, 255, 255, 0.6);
}

/* Panneaux secondaires */
.secondaryPanels {
    position: relative;
    z-index: 5;
    will-change: transform;
}

.secondaryPanel {
    background: rgba(0, 95, 255, 0.5);
    backdrop-filter: blur(10px);
    border: 2px solid rgba(0, 0, 0, 0.2);
    padding: 0.8vw;
    border-radius: 10px;
    width: 10vw;
    max-width: 30vw;
    min-width: 10vw;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    position: absolute;
    opacity: 0;
    height: 0;
    transform: scale(0.8);
    transform-origin: top;
    visibility: hidden;
    transition:
        opacity 0.4s ease-in-out,
        transform 0.4s ease-in-out,
        height 0.4s ease-in-out,
        visibility 0.4s ease-in-out;
    overflow: hidden;
    cursor: pointer;
    will-change: transform, opacity, height;
}

.secondaryPanel.is-open {
    opacity: 1;
    height: auto;
    transform: scale(1);
    visibility: visible;
    transition:
        opacity 0.4s ease-in-out,
        transform 0.4s ease-in-out,
        height 0.4s ease-in-out,
        visibility 0.4s ease-in-out;
}

.secondaryPanel:hover {
    transform: translateY(-10px) scale(1.2);
    box-shadow: 0 15px 30px rgba(0, 95, 255, 0.7), 0 8px 20px rgba(0, 0, 255, 0.4);
}

.secondaryPanel::after {
    content: "🔍";
    position: absolute;
    top: 20%;
    left: 90%;
    transform: translate(-50%, -50%);
    font-size: 2vw;
    color: rgba(255, 255, 255, 0);
    transition: color 0.3s ease;
    z-index: -1;
}

.secondaryPanel:hover::after {
    color: rgba(255, 255, 255, 1);
}

/* Animation pour la transition titre -> description */
.definitionTitle {
    font-size: 1.1vw;
    font-weight: bold;
    text-align: center;
    color: #FFFFFF;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
    transition: opacity 0.4s ease, height 0.4s ease;
    opacity: 1;
}

.definitionContent {
    font-size: 0.9vw;
    color: #DDDDDD;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
    font-weight: bold;
    text-align: center;
    opacity: 0;
    height: 0;
    transition: opacity 0.4s ease, height 0.4s ease;
}

.secondaryPanel:hover .definitionTitle {
    opacity: 0;
    height: 0;
}

.secondaryPanel:hover .definitionContent {
    opacity: 1;
    height: auto;
}


/* Changement de contenu (titre vers description) au survol des panneaux secondaires */
.definitionTitle {
    font-size: 1.1vw;
    font-weight: bold;
    text-align: center;
    color: #FFFFFF;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
    transition: opacity 0.4s ease, height 0.4s ease;
    opacity: 1;

    /* interdire le curseur */
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.definitionContent {
    font-size: 0.9vw;
    color: #DDDDDD;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
    font-weight: bold;
    text-align: center;
    opacity: 0;
    height: 0;
    transition: opacity 0.4s ease, height 0.4s ease;
}

.secondaryPanel:hover .definitionTitle {
    opacity: 0;
    height: 0;
}

.secondaryPanel:hover .definitionContent {
    opacity: 1;
    height: auto;
}



/* secondary panel positionning */
.panel-group-0 .panel-0 {
    top: -10vw;
    left: -14vw;
}

.panel-group-0 .panel-1 {
    top: 2vw;
    left: -14vw;
}

.panel-group-0 .panel-2 {
    top: -4vw;
    left: -14vw;
}

.panel-group-0 .panel-3 {
    top: -8vw;
    left: 14vw;
}

.panel-group-0 .panel-4 {
    top: -1vw;
    left: 14vw;
}



.panel-group-1 .panel-0 {
    top: -6vw;
    left: -22vw;
}

.panel-group-1 .panel-1 {
    top: -6vw;
    left: -8vw;
}

.panel-group-1 .panel-2 {
    top: -12vw;
    left: -22vw;
}

.panel-group-1 .panel-3 {
    top: -17vw;
    left: -8vw;
}

.panel-group-1 .panel-4 {
    top: -12vw;
    left: -8vw;
}

.panel-group-1 .panel-5 {
    top: -17vw;
    left: -22vw;
}


.panel-group-2 .panel-0 {
    top: -9vw;
    left: 4vw;
}

.panel-group-2 .panel-1 {
    top: -9vw;
    left: 18vw;
}

.panel-group-2 .panel-2 {
    top: -15vw;
    left: 4vw;
}

.panel-group-2 .panel-3 {
    top: -20vw;
    left: 18vw;
    z-index: 2;
}

.panel-group-2 .panel-4 {
    top: -15vw;
    left: 18vw;
}

.panel-group-2 .panel-5 {
    top: -20vw;
    left: 4vw;
}


.panel-group-3 .panel-0 {
    top: -10vw;
    left: -1vw;
}

.panel-group-3 .panel-1 {
    top: 1vw;
    left: 11vw;
}

.panel-group-3 .panel-2 {
    top: 1vw;
    left: -13vw;
}


.panel-group-4 .panel-0 {
    top: 1vw;
    left: -1vw;
}

.panel-group-4 .panel-1 {
    top: -11vw;
    left: -11vw;
}

.panel-group-4 .panel-2 {
    top: -11vw;
    left: 9vw;
}


.panel-group-5 .panel-0 {
    top: -11vw;
    left: -13vw;
}

.panel-group-5 .panel-1 {
    top: 1vw;
    left: -13vw;
}

.panel-group-5 .panel-2 {
    top: 1vw;
    left: 11vw;
}

.panel-group-5 .panel-3 {
    top: -11vw;
    left: 11vw;
}