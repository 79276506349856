.fog1Container,
.fog2Container,
.fog3Container,
.fog4Container,
.fog5Container,
.fog6Container {
    position: absolute;
    opacity: 0;
    visibility: hidden;
}

.fog1 {
    width: 45vw;
    height: auto;
}

.fog2 {
    width: 45vw;
    height: auto;
}

.fog3 {
    width: 40vw;
    height: auto;
}

.fog4,
.fog5,
.fog6 {
    width: 15vw;
    height: auto;
}

.theTContainer,
.theHContainer,
.theEContainer {
    position: absolute;
    z-index: 4;
}

.scrumSContainer,
.scrumCContainer,
.scrumRContainer,
.scrumUContainer,
.scrumMContainer {
    position: absolute;
    z-index: 5;
}

.nomadNContainer,
.nomadOContainer,
.nomadMContainer,
.nomadAContainer,
.nomadDContainer {
    position: absolute;
    z-index: 6;
}

.theTContainer {
    top: 10vw;
    left: 28vw;
}

.theHContainer {
    top: 10vw;
    left: 35vw;
}

.theEContainer {
    top: 12.5vw;
    left: 40vw;
}

.nomadNContainer {
    top: 25vw;
    left: 36vw;
}

.nomadOContainer {
    top: 26.5vw;
    left: 43vw;
}

.nomadMContainer {
    top: 26.9vw;
    left: 50.5vw;
}

.nomadAContainer {
    top: 27vw;
    left: 58vw;
}

.nomadDContainer {
    top: 26vw;
    left: 64.3vw;
}

.scrumSContainer {
    top: 17.7vw;
    left: 33vw;
}

.scrumCContainer {
    top: 19.30vw;
    left: 39vw;
}

.scrumRContainer {
    top: 19.5vw;
    left: 45vw;
}

.scrumUContainer {
    top: 19.6vw;
    left: 50vw;
}

.scrumMContainer {
    top: 19.5vw;
    left: 55.8vw;
}

.fog1Container {
    top: 11vw;
    left: 24vw;
    z-index: 3;
}

.fog2Container {
    top: 16vw;
    left: 28vw;
    z-index: 5;
}

.fog3Container {
    top: 20vw;
    left: 31vw;
    z-index: 4;
}

.fog4Container {
    top: 17vw;
    left: 53vw;
    z-index: 4;
    display: none;
}

.fog5Container {
    top: 12vw;
    left: 35vw;
    z-index: 5;
}

.fog6Container {
    top: 22vw;
    left: 41vw;
    z-index: 7;
}

.theT {
    width: 7vw;
    height: auto;
}

.theH {
    width: 5vw;
    height: auto;
}

.theE {
    width: 5vw;
    height: auto;
}

.scrumS {
    width: 7vw;
    height: auto;
}

.scrumC {
    width: 5.6vw;
    height: auto;
}

.scrumR {
    width: 4.8vw;
    height: auto;
}

.scrumU {
    width: 5vw;
    height: auto;
}

.scrumM {
    width: 7.6vw;
    height: auto;
}

.nomadN {
    width: 7.5vw;
    height: auto;
}

.nomadO {
    width: 7vw;
    height: auto;
}

.nomadM {
    width: 7vw;
    height: auto;
}

.nomadA {
    width: 6vw;
    height: auto;
}

.nomadD {
    width: 6vw;
    height: auto;
}