.loadingScreen {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    overflow: hidden;
}

.loadingScreenBackground {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: -1;
}

.loadingContent {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.logoAnimationContainer {
    position: absolute;
    top: 5vh;
    left: 14vw;
}

.logoAnimationContainer .logo-container {
    width: 8vw;
    height: 8vw;
    z-index: 1;
}

.loadingTitle {
    position: absolute;
    top: 9vh;
    text-align: center;
}

.loadingTitle h1 {
    color: whitesmoke;
    font-family: 'Julius Sans One', sans-serif;
    letter-spacing: 0.5vw;
    font-size: 5vh;
    margin: 0 25vh;
}

.loadingTitle h2 {
    color: whitesmoke;
    font-family: 'Dancing Script', cursive;
    font-size: 3vh;
    letter-spacing: 0.5vw;
    margin-top: 1vh;
    margin-left: 34vw;
}

.loadingTitle .trademark,
.loadingTitle .copyright {
    font-size: 2vh;
    vertical-align: super;
    margin-left: 0.5vw;
}

.launchButton {
    cursor: pointer;
    border: none;
    border-radius: 50%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: absolute;
    top: 35vh;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    width: 28vh;
}

.launchButton img {
    width: 100%;
    height: auto;
}

.launchButton:hover:not(:disabled) {
    transform: scale(1.5);
    animation: waves 2s linear infinite;
}

.launchButton:disabled {
    cursor: not-allowed;
}

.spinner {
    position: absolute;
    width: 40px;
    height: 40px;
    text-align: center;
    -webkit-animation: sk-rotate 2.0s infinite linear;
    animation: sk-rotate 2.0s infinite linear;
}


.dot1,
.dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    border-radius: 100%;
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
    z-index: 11;
}

.dot1 {
    background-color: #2d8e99;
}

.dot2 {
    top: auto;
    bottom: 0;
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
    background-color: #325b91;

}

@-webkit-keyframes sk-rotate {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes sk-rotate {
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes sk-bounce {

    0%,
    100% {
        -webkit-transform: scale(0.0);
    }

    50% {
        -webkit-transform: scale(1.0);
    }
}

@keyframes sk-bounce {

    0%,
    100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    }

    50% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}

@keyframes waves {
    0% {
        box-shadow: 0 0 0 0rem rgba(0, 1, 1, 0.2), 0 0 0 1rem rgba(0, 1, 1, 0.2),
            0 0 0 2rem rgba(0, 1, 1, 0.2), 0 0 0 5rem rgba(0, 1, 1, 0.2);
    }

    100% {
        box-shadow: 0 0 0 1rem rgba(0, 1, 1, 0.2), 0 0 0 2rem rgba(0, 1, 1, 0.2),
            0 0 0 5rem rgba(0, 1, 1, 0.2), 0 0 0 8rem rgba(0, 1, 1, 0);
    }
}