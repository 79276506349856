.launchButton img {
    transition: opacity 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.launchButton.disabled img {
    opacity: 0.5;
    cursor: not-allowed;
}

.launchButton.disabled .overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: red;
    font-size: 2vw;
    font-family: 'Julius Sans One', sans-serif;
    pointer-events: none;
    animation: blink 1s infinite;
}

@keyframes blink {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }
}

.logoAnimationContainer {
    position: absolute;
    top: 5vh;
    left: 5vw;
}

.logoAnimationContainer .logo-container {
    width: 8vw;
    height: 8vw;
}

.countdown {
    position: absolute;
    top: 60vh;
    text-align: center;
    color: whitesmoke;
    font-family: 'Julius Sans One', sans-serif;
}

.countdown p {
    font-size: 2.5vh;
}

.countdown h3 {
    font-size: 3vh;
}